import React, {useContext, useEffect, useState, useRef} from 'react'
import {I18nContext} from '../../../context/i18n/I18nContext'
import type {QuickSelectI18nScope} from '../QuickSelect'
import './Footer.scss'

interface Props {
  onAddRecipientsClick: () => void
  footerMessage: {text: string; type: string}
  lastNonContactableUser: string
  disabled: boolean
}

// needs to be in synch with the animation in Footer.scss
const MILISECONDS_TO_SHOW_TEMPORARY_MESSAGE = 3000

const Footer = ({onAddRecipientsClick, footerMessage, lastNonContactableUser, disabled}: Props) => {
  const i18n = useContext(I18nContext) as QuickSelectI18nScope
  const i18nQS = i18n.quick_select
  const [message, setMessage] = useState<string>('')
  const [className, setClassName] = useState<string>('')
  const statusClassName = footerMessage.type === 'warning' ? 'status status-warning' : 'info'
  const timeoutId = useRef<ReturnType<typeof setTimeout> | null>(null)
  const resetTimeout = () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }
  }
  useEffect(() => {
    if (!lastNonContactableUser) {
      resetTimeout()
      setMessage(footerMessage.text)
      setClassName('')
    }
    if (lastNonContactableUser) {
      resetTimeout()
      setMessage(`${lastNonContactableUser} ${i18nQS.footer.is_not_contactable}`)
      setClassName('fade out')
      timeoutId.current = setTimeout(() => {
        setMessage(footerMessage.text)
        setClassName('')
      }, MILISECONDS_TO_SHOW_TEMPORARY_MESSAGE)
    }
  }, [lastNonContactableUser, setMessage, footerMessage, i18nQS])

  return (
    <div className="footer-container">
      <div key={message} className={`footer-message ${className} ${statusClassName}`}>
        {message}
      </div>
      <button
        type="button"
        onClick={onAddRecipientsClick}
        disabled={disabled}
        className="footer-button btn btn-primary"
        data-testid="add-recipients-button"
      >
        {i18nQS.footer.add_recipients}
      </button>
    </div>
  )
}

export default Footer
