import React, {useState, useContext} from 'react'
import {I18nContext} from '../../../context/i18n/I18nContext'
import {Tooltip} from 'react-tooltip'
import './AddRecipientsContent.scss'
import type {Recipient, WhoToAddKey, QuickSelectI18nScope} from '../QuickSelect'

interface Props {
  loading: boolean
  error: boolean
  currentUser: {id: number; role: string}
  recipientType: WhoToAddKey
  recipients: Recipient[]
  onRecipientClick: (recipient: Recipient) => void
  selectedRecipients: Recipient[]
  finalElement: React.ReactElement | null
}

const hasRecipient = (selectedRecipients: Recipient[], recipient: Recipient) =>
  !!selectedRecipients.find(
    (selectedRecipient) =>
      recipient.type === selectedRecipient.type && recipient.id === selectedRecipient.id
  )

const normalizeSearchTerm = (text: string) => text.normalize('NFD').replace(/\p{Diacritic}/gu, '')

const matchesSearchTerm =
  (hasSearchTerm: boolean, searchTerm: string) => (recipient: Recipient) => {
    if (hasSearchTerm) {
      const regex = new RegExp(searchTerm, 'i')
      let matches = regex.test(normalizeSearchTerm(recipient.full_name))
      if (recipient.type === 'Student') {
        matches ||= !!recipient.parents?.some((parent) =>
          regex.test(normalizeSearchTerm(parent.full_name))
        )
      }
      return matches
    } else {
      return true
    }
  }

const EmptyState = ({recipientType}: {recipientType: WhoToAddKey}) => {
  const i18n = useContext(I18nContext) as QuickSelectI18nScope
  const i18nQS = i18n.quick_select
  const title = {
    user: i18nQS.recipient_list.empty.people,
    section: i18nQS.recipient_list.empty.classes,
    group: i18nQS.recipient_list.empty.groups,
  }

  return (
    <div className="empty-state">
      <div>
        <p className="empty-state-title">{title[recipientType]}</p>
        <p>{i18nQS.recipient_list.empty.suggestion}</p>
      </div>
    </div>
  )
}

const LoadingState = () => {
  return (
    <div className="loading-state" data-testid="loading-spinner">
      <i className="fa-solid fa-spinner-third fa-spin" />
    </div>
  )
}

const ErrorState = () => {
  const i18n = useContext(I18nContext) as QuickSelectI18nScope
  const i18nQS = i18n.quick_select

  return (
    <div className="empty-state">
      <div>
        <p className="empty-state-title">{i18nQS.recipient_list.error.header}</p>
        <p>{i18nQS.recipient_list.error.subheader}</p>
      </div>
    </div>
  )
}

const AddRecipientsContent = ({
  loading,
  error,
  recipients,
  onRecipientClick,
  selectedRecipients,
  recipientType,
  currentUser,
  finalElement,
}: Props) => {
  const i18n = useContext(I18nContext) as QuickSelectI18nScope
  const i18nQS = i18n.quick_select
  const [searchTerm, setSearchTerm] = useState('')
  const hasSearchTerm = searchTerm.trim() !== ''
  const recipientsToShow = recipients.filter(matchesSearchTerm(hasSearchTerm, searchTerm))

  const title: {[key in WhoToAddKey]: string} = {
    user: i18nQS.recipient_list.select_people,
    section: i18nQS.recipient_list.select_classes,
    group: i18nQS.recipient_list.select_groups,
  }

  const tooltipContentByRole: {[_: string]: string} = {
    teacher: i18nQS.recipient_list.tooltip.teacher,
    principal: i18nQS.recipient_list.tooltip.principal,
    staff: i18nQS.recipient_list.tooltip.staff,
    parent: i18nQS.recipient_list.tooltip.parent,
  }

  return (
    <div className="select-recipient">
      <div className="select-recipient-title">
        <div className="select-recipient-title-text">{title[recipientType]}</div>
        {recipientType === 'user' && (
          <>
            <i
              data-tooltip-id="contextual-tooltip"
              data-tooltip-class-name="select-recipient-title-tooltip"
              className="fa-regular fa-circle-info select-recipient-info"
            />
            <Tooltip
              id="contextual-tooltip"
              place="right"
              content={
                tooltipContentByRole[currentUser.role] || i18nQS.recipient_list.tooltip.generic
              }
            />
          </>
        )}
      </div>
      <div className="select-recipient-container">
        <div className="select-recipient-list">
          <div className="search-container">
            <i className="fa-regular fa-magnifying-glass search-icon" />
            <input
              className="search-input"
              data-testid="search-input"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={i18nQS.recipient_list.search}
            />
          </div>
          <div className="list-container">
            {error && <ErrorState />}
            {loading && <LoadingState />}
            {!loading && !error && recipientsToShow.length === 0 && (
              <EmptyState {...{recipientType}} />
            )}
            {!loading && !error && recipientsToShow.length > 0 && (
              <div data-testid="recipient-options">
                {recipientsToShow.map((recipient, index) => (
                  <label
                    data-testid="recipient-option-label"
                    key={index}
                    className={`list-item${index % 2 ? ' even' : ''} ${
                      hasRecipient(selectedRecipients, recipient) ? ' selected' : ''
                    }`}
                  >
                    <div className="left-selector-container">
                      <input
                        onChange={() => onRecipientClick(recipient)}
                        className="people-list-check"
                        type="checkbox"
                        checked={hasRecipient(selectedRecipients, recipient)}
                      />
                    </div>
                    <div className="right-content-container">
                      <div className="name">{recipient.full_name}</div>
                      <div className="description">{recipient.title}</div>
                    </div>
                  </label>
                ))}
                {finalElement}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddRecipientsContent
