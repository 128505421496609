import React, {useContext} from 'react'
import {I18nContext} from '../../../context/i18n/I18nContext'
import './WhoToAdd.scss'
import type {WhoToAddOption, QuickSelectI18nScope} from '../QuickSelect'

interface Props {
  title: string
  showAssociatedParentsMessage: boolean
  whoToAddOptions: WhoToAddOption[]
  onWhoToAddChange: (option: string) => void
  whoToAdd: string[]
}

const WhoToAdd = ({
  title,
  showAssociatedParentsMessage,
  whoToAddOptions,
  onWhoToAddChange,
  whoToAdd,
}: Props) => {
  const i18n = useContext(I18nContext) as QuickSelectI18nScope
  const i18nQS = i18n.quick_select
  return (
    <>
      {showAssociatedParentsMessage ? (
        <div className="no-student-square">
          <i className="fa-solid fa-circle-info" />
          <div className="no-student-square-message">
            {i18nQS.who_to_add.no_student_square_message}
          </div>
        </div>
      ) : (
        <div className="who-to-add">
          <div className="who-to-add-title">{title}</div>
          <div className="who-to-add-options">
            {whoToAddOptions.map((whoToAddOption) => (
              <div key={whoToAddOption.key} className="who-to-add-option">
                <label
                  key={whoToAddOption.key}
                  htmlFor={whoToAddOption.key}
                  className="who-to-add-description"
                >
                  <input
                    onChange={() => onWhoToAddChange(whoToAddOption.key)}
                    id={whoToAddOption.key}
                    className="who-to-add-check"
                    type="checkbox"
                    checked={whoToAdd.includes(whoToAddOption.key)}
                  />
                  {whoToAddOption.description}
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default WhoToAdd
